import { useEffect, useState } from "react";

import {
  faSortAlphaDown,
  faSortAlphaUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import queryString from "query-string";
import { useLocation, Link } from "react-router-dom";

import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";

const PlaceList = () => {
  window.scrollTo(0, 0);

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [places, setPlaces] = useState([]);
  const columns = [
    { key: "cherokeeName", name: "Cherokee Name", class: "col-3" },
    { key: "englishName", name: "English Name", class: "col-3" },
    { key: "townType", name: "Town Type", class: "col-3" },
    // { key: "location", name: "Location", class: "col-2" },
    { key: "preview", name: "Description", class: "col-3" },
  ];

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Places`;

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/place/${location.search}`;

    axios.get(url, { withCredentials: true }).then((res) => {
      const places = res.data;

      setPlaces(places);
      setIsLoading(false);
    });
  }, [location.search]);

  const baseHeadings = columns.map((column) => {
    let query = queryString.parse(location.search);
    const sortActive = query.sort === column.key;
    const sortColor = sortActive ? "#8f151e" : "#575943";
    const activeDirection =
      (query.direction ? query.direction : "asc") === "asc" ? "desc" : "asc";
    const direction = sortActive ? activeDirection : "asc";
    query.sort = column.key;
    query.direction = direction;
    const search = queryString.stringify(query, {
      arrayFormat: "bracket",
    });
    return (
      <th key={column.key} className={column.class}>
        {column.name}{" "}
        <Link
          to={{
            pathname: location.pathname,
            search: search,
          }}
        >
          {direction === "asc" ? (
            <FontAwesomeIcon
              icon={faSortAlphaUp}
              color={sortColor}
              transform="shrink-2"
            />
          ) : (
            <FontAwesomeIcon
              icon={faSortAlphaDown}
              color={sortColor}
              transform="shrink-2"
            />
          )}
        </Link>
      </th>
    );
  });

  const rows = places.map((place) => {
    return (
      <tr key={place.id} className="d-flex">
        <td className="col-3">
          <Link to={`/place/${place.slug}`}>{place.cherokeeName}</Link>
        </td>
        <td className="col-3">{place.englishName}</td>
        <td className="col-3">{place.townType}</td>
        {/* <td>{place.meaning}</td>  */}
        {/* <td>{place.type}</td> */}
        {/* <td className="col-2 text-break">{place.location}</td> */}
        <td className="col-3">
          <div className="place-content">
            <HtmlSnippet snippet={place.preview} />
          </div>
        </td>
        {/* <td className="col-1">
          <Link to={`/place/${place.slug}`}>view</Link>
        </td> */}
      </tr>
    );
  });

  return (
    <>
      {/* <div className="background-map"></div> */}
      <div className="row mt-4">
        <div className="col-12">
          <h1>Places</h1>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12">
          {/* <h1>Places</h1> */}
          <table className="table table-light table-striped place-table">
            <thead>
              <tr className="d-flex">{baseHeadings}</tr>
            </thead>
            <tbody>
              {isLoading ? (
                <tr>
                  <td>
                    <Loading />
                  </td>
                </tr>
              ) : (
                <>{rows}</>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default PlaceList;
