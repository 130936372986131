import React from "react";

import { faMapMarker, faCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { townTypes } from "../../utils/entityTypes";

function PinIcon({ size = "fa-3x", placeType = "Cultural Places", slug = "" }) {
  const activeColor = townTypes.hasOwnProperty(placeType)
    ? townTypes[placeType].color
    : "gray";

  return (
    <div className={size} data-slug={slug}>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon
          icon={faMapMarker}
          color={activeColor}
          transform="shrink-2"
        />
        <FontAwesomeIcon
          icon={faCircle}
          color={"#ffffff"}
          transform="shrink-12 up-2"
        />
      </span>
    </div>
  );
}

export default PinIcon;
