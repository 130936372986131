import { useEffect, useState } from "react";

import axios from "axios";
import { useParams } from "react-router-dom";

import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";
import PlaceContainer from "../map/container/PlaceContainer";

const PlaceShow = () => {
  window.scrollTo(0, 0);

  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const emptyPlace = {
    id: 0,
    cherokeeName: "",
    englishName: "",
    meaning: "",
    location: "",
    type: "",
    townType: "",
    slug: "",
    content: "",
    notes: "",
    geoPoint: "",
  };
  const [place, setPlace] = useState(emptyPlace);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Places: ${place.cherokeeName}`;
  }, [place]);

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/place/${slug}`;

    axios
      .get(url, { withCredentials: true })
      .then((res) => {
        const place = res.data;

        setError("");
        setPlace(place);
        setIsLoading(false);
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          // console.log(error.response);
          setError(error.response.data.error);
          setPlace(emptyPlace);
          setIsLoading(false);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
        // console.log(error.config);
      });
  }, [slug]);

  return (
    <div className="row mt-4">
      {/* <div className="background-map"></div> */}
      <div className="col-12">
        {isLoading ? (
          <div className="row">
            <div className="col-12">
              <Loading />
            </div>
          </div>
        ) : (
          <>
            {error !== "" ? (
              <div className="row">
                <div className="col-12">
                  <div className="text-danger">{error}</div>
                </div>
              </div>
            ) : (
              <>
                <div className="row mb-2">
                  <div className="col-12">
                    <h1>{place.cherokeeName}</h1>
                  </div>
                </div>
                <div className="row">
                  <div
                    className="col-12"
                    style={{ display: "grid", gridTemplateRows: "1fr" }}
                  >
                    <div
                      // className="d-flex flex-column"
                      style={{ display: "grid", gridTemplateRows: "400px" }}
                    >
                      {place.geoPoint !== "" && (
                        <PlaceContainer place={place} />
                      )}
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-12">
                    <table className="table table-light">
                      {/* <thead></thead> */}
                      <tbody>
                        {place.englishName !== "" && (
                          <tr>
                            <th>English Name</th>
                            <td>{place.englishName}</td>
                          </tr>
                        )}
                        {place.townType !== "" && (
                          <tr>
                            <th>Town Type</th>
                            <td>{place.townType}</td>
                          </tr>
                        )}
                        {place.meaning !== "" && (
                          <tr>
                            <th>Meaning</th>
                            <td>{place.meaning}</td>
                          </tr>
                        )}
                        {place.type !== "" && (
                          <tr>
                            <th>Type</th>
                            <td>{place.type}</td>
                          </tr>
                        )}
                        {/* {place.location !== "" && (
                          <tr>
                            <th>Location</th>
                            <td>{place.location}</td>
                          </tr>
                        )} */}
                        {place.geoPoint !== "" && (
                          <tr>
                            <th>Geo Point</th>
                            <td>{place.geoPoint}</td>
                          </tr>
                        )}
                        {place.notes !== "" && (
                          <tr>
                            <th>Notes</th>
                            <td>{place.notes}</td>
                          </tr>
                        )}
                        {place.content !== "" && (
                          <tr>
                            <th>Description</th>
                            <td>
                              <div className="place-content">
                                <HtmlSnippet snippet={place.content} />
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default PlaceShow;
