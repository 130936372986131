import { Fragment } from "react";

import ReactHtmlParser, {
  // processNodes,
  convertNodeToElement,
  // htmlparser2,
} from "react-html-parser";
// import { useLocation } from "react-router-dom";

// import divider from "../image/divider.svg";
import ClickImage from "../layout/ClickImage";
import HtmlSnippet from "./HtmlSnippet";
import PlaceTable from "./PlaceTable";

const DOMPurify = require("dompurify")(window);

const DisplayParallaxPost = ({ post, displayTitle = true }) => {
  // const location = useLocation();
  // const { pathname } = location;

  const contentContainer = document.createElement("div");
  contentContainer.innerHTML = post.content;

  const children = contentContainer.querySelectorAll("div > :not(hr)");
  // console.log(children);

  let layerElements = [];

  // let layerElementIndex = 0;
  let firstLayer = true;
  let textElements = [];
  Array.from(children).forEach((child) => {
    if (
      child.tagName === "FIGURE" &&
      Array.from(child.classList).includes("image")
    ) {
      // let image = null;
      // if (firstLayer) {
      //   firstLayer = false;
      // } else {
      //   image = child;
      // }
      const image = child;
      layerElements.push({ image: image, text: [...textElements] });
      textElements = [];
    } else {
      textElements.push(child);
    }
  });
  if (textElements.length > 0) {
    layerElements.push({ image: null, text: [...textElements] });
  }
  // console.log(layerElements);

  const layers = layerElements.map((child, index) => (
    <Fragment key={index}>
      {/* <div className="parallax__group" style={{ height: "20vh" }}>
        <div className="parallax__layer parallax__layer--base">
          <div className="title"></div>
        </div>
        <div className="parallax__layer parallax__layer--back">
          <div className="title">
            TITLE?
          </div>
        </div>
      </div> */}
      <div className="parallax__group" style={{ zIndex: 10 }}>
        <div className="parallax__layer parallax__layer--fore">
          <div className="title">
            {child.image && (
              <div>
                {ReactHtmlParser(DOMPurify.sanitize(child.image.innerHTML), {
                  transform: (node) => {
                    if (node.name === "img") {
                      return <ClickImage src={node.attribs.src} />;
                    }
                    return convertNodeToElement(node);
                  },
                })}
              </div>
            )}
          </div>
        </div>
        <div className="parallax__layer parallax__layer--base">
          <div className="title">
            {/* <img
            src={storyImages[post.slug]}
            className="img-thumbnail"
            style={{ width: "50vw" }}
            alt={post.title}
          /> */}
            {/* {child.image && <HtmlSnippet snippet={child.image.innerHTML} />} */}
            {/* {child.image && (
              <div>
                {ReactHtmlParser(child.image.innerHTML, {
                  transform: (node) => {
                    if (node.name === "img") {
                      console.log(node);
                      return (
                        <img
                          src={node.attribs.src}
                          style={{ zIndex: 10000000 }}
                          alt="story"
                          onClick={() => console.log("click")}
                        />
                      );
                    }
                    return convertNodeToElement(node);
                  },
                })}
              </div>
            )} */}
          </div>
        </div>
      </div>
      <div className="parallax__group" style={{ height: "100hh" }}>
        {/* <div className="parallax__layer parallax__layer--base">
        <div className="title"></div>
      </div> */}
        <div className="parallax__layer parallax__layer--back">
          <div className="title-show">
            {child.text.length > 0 ? (
              <>
                {child.text.map((text, tIndex) => (
                  <HtmlSnippet key={tIndex} snippet={text.outerHTML} />
                ))}
              </>
            ) : (
              <></>
            )}
            {/* <Link className="text-cr-red" to={`/story/${post.slug}`}>
              Read More…
            </Link> */}
            <div style={{ margin: "10rem 0" }}>
              {/* <img src={divider} style={{ width: "25vw" }} alt="divider" /> */}
              &nbsp;
            </div>
          </div>
        </div>
        {/* <div className="parallax__layer parallax__layer--deep">
        <div className="title"></div>
      </div> */}
      </div>
      {/* <div className="parallax__group" style={{ height: "100vh" }}>
      <div className="parallax__layer parallax__layer--base">
        <div className="title"></div>
      </div>
      <div className="parallax__layer parallax__layer--back">
        <div className="title">
          <img src={divider} style={{ width: "25vw" }} alt="divider" />
        </div>
      </div>
      <div className="parallax__layer parallax__layer--deep">
        <div className="title"></div>
      </div>
    </div> */}
    </Fragment>
  ));

  const divs = (
    <div className="parallax post-show">
      <div className="parallax__group" style={{ height: "100vh" }}>
        <div className="parallax__layer parallax__layer--base">
          <div className="title-show"></div>
        </div>
        <div className="parallax__layer parallax__layer--back">
          <div className="title-show title-show-header">{post.title}</div>
        </div>
      </div>
      {/* <div className="parallax__group" style={{ zIndex: 10 }}>
        <div className="parallax__layer parallax__layer--fore">
          <div className="title"></div>
        </div>
        <div className="parallax__layer parallax__layer--base">
          <div className="title">
            <img
              src={oconaluftee4x3}
              className="img-thumbnail"
              style={{ width: "50vw" }}
              alt="Riverbank"
            />
          </div>
        </div>
      </div> */}
      {/* <div className="parallax__group" style={{ height: "10vh" }}>
        <div className="parallax__layer parallax__layer--base">
          <div className="title"></div>
        </div>
        <div className="parallax__layer parallax__layer--back">
          <div className="title">
            <img src={divider} style={{ width: "100vw" }} alt="divider" />
          </div>
        </div>
        <div className="parallax__layer parallax__layer--deep">
          <div className="title"></div>
        </div>
      </div> */}
      {layers}
    </div>
  );

  return divs;
  return (
    <div>
      {/* {displayTitle && (
        <>
          {pathname.startsWith("/map") ? (
            <h1 className="">
              <Link to={`/story/${post.slug}`}>{post.title}</Link>
            </h1>
          ) : (
            <h1 className="">{post.title}</h1>
          )}
        </>
      )} */}
      <div className="mt-2">
        {/* <HtmlSnippet snippet={post.content} /> */}
        {divs}
      </div>
      {post.places.length > 0 && (
        <>
          <h2>Places linked to this story</h2>
          <PlaceTable places={post.places} />
        </>
      )}
    </div>
  );
};

export default DisplayParallaxPost;
