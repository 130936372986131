const DOMPurify = require("dompurify")(window);

const HtmlSnippet = ({ snippet }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(snippet) }}
    ></div>
  );
};
export default HtmlSnippet;
