export const townTypes = {
  "Cultural Places": { name: "Cultural Places", color: "#d60000" },
  "Overhill Towns": { name: "Overhill Towns", color: "#d67d00" },
  "Out Towns": { name: "Out Towns", color: "#d6c100" },
  "Middle Towns": { name: "Middle Towns", color: "#00d66b" },
  "Valley Towns": { name: "Valley Towns", color: "#008D9C" },
  "Lower Towns": { name: "Lower Towns", color: "#0036d6" },
  "Chikamauga Towns": { name: "Chikamauga Towns", color: "#6b00d6" },
  "New Towns": { name: "New Towns", color: "#d60088" },
};
