const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <footer className="page-footer ">
      <div className="container">
        <div className="row">
          <div className="col mt-2 mb-2">
            Cherokee Riverkeepers © <span className="year">{year}</span>{" "}
            <a href="https://www.dhi.ac.uk/">DHI</a>{" "}
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
