import { Collapse } from "bootstrap";
import { NavLink } from "react-router-dom";

const PostNavBar = ({ title, posts, path = "/story/" }) => {
  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-light post-navbar">
      <div className="container">
        <div>
          <NavLink to="/story" className="navbar-brand">
            Stories:
          </NavLink>
          <span className="d-lg-none">{title}</span>
        </div>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarStory"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarStory">
          <div className="navbar-nav flex-row flex-wrap">
            {posts.map((post) => {
              return (
                <NavLink
                  key={post.slug}
                  exact={true}
                  to={`${path}${post.slug}`}
                  className="nav-link col-6 col-sm-4 col-lg-auto fs-6"
                  activeClassName="active"
                >
                  {post.title}
                </NavLink>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default PostNavBar;
