import { useEffect, useState, useContext } from "react";

import axios from "axios";
import { Link, useParams, useLocation } from "react-router-dom";

import DisplayParallaxPost from "../layout/DisplayParallaxPost";
import DisplayPost from "../layout/DisplayPost";
import Loading from "../layout/Loading";
import PostNavBar from "../layout/PostNavbar";
import { Context } from "../Store";

const PostShow = ({ hardSlug = null }) => {
  window.scrollTo(0, 0);

  let location = useLocation();

  const { slug: softSlug } = useParams();
  const slug = hardSlug || softSlug;

  const [state] = useContext(Context);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const emptyPost = {
    id: 0,
    title: "",
    content: "",
    slug: "",
    places: [],
  };
  const [post, setPost] = useState(emptyPost);

  let navBar = Loading;

  if (!state.error && state.posts) {
    navBar = (
      <PostNavBar
        title={post.title}
        posts={state.posts}
        path="/story/"
        style={{}}
      />
    );
  }

  useEffect(() => {
    document.title = location.pathname.startsWith("/story")
      ? `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Stories: ${post.title}`
      : `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - ${post.title}`;
  }, [post]);

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/post/${slug}`;

    axios
      .get(url, { withCredentials: true })
      .then((res) => {
        const post = res.data;
        setError("");
        setPost(post);
        setIsLoading(false);
      })
      .catch((error) => {
        // Error
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
          // console.log(error.response);
          setError(error.response.data.error);
          setPost(emptyPost);
          setIsLoading(false);
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the
          // browser and an instance of
          // http.ClientRequest in node.js
          // console.log(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // console.log("Error", error.message);
        }
        // console.log(error.config);
      });
  }, [slug]);

  const places = post.places.map((place) => (
    <li key={place.id}>
      <Link to={`/place/${place.slug}`}>{place.cherokeeName}</Link>
    </li>
  ));

  return (
    <>
      {/* <div className="background-map"></div> */}
      <div> {hardSlug === null && navBar}</div>
      {/* <div style={{ overflow: "hidden scroll" }}> */}
      <div className="container">
        <div className="row mt-3">
          <div className="col-12">
            {isLoading ? (
              <Loading />
            ) : (
              <>
                {error !== "" ? (
                  // TODO: make error component
                  <div className="text-danger">{error}</div>
                ) : (
                  <DisplayPost post={post} displayTitle={hardSlug === null} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {/* </div> */}
      {/* 
      <div className="col-12 col-md-12 col-lg-12 col-xl-12">
        {hardSlug === null && navBar}
        {isLoading ? (
          <Loading />
        ) : (
          <>
            {error !== "" ? (
              // TODO: make error component
              <div className="text-danger">{error}</div>
            ) : (
              // <div className="row mb-2">
              //   <div className="col-12">
              //     <DisplayParallaxPost
              //       post={post}
              //       displayTitle={hardSlug === null}
              //     />
              //   </div>
              // </div>
              <div className="container">
                <div className="row mt-3">
                  <div className="col-12">
                    <DisplayPost post={post} displayTitle={hardSlug === null} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div> */}

      {/* </div> */}
    </>
  );
};

export default PostShow;
