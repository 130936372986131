// import { useEffect } from "react";

import oconaluftee16x9 from "../image/oconaluftee-16x9.jpg";
// import oconaluftee21x9 from "../image/oconaluftee-21x9.jpg";
// import oconaluftee2x1 from "../image/oconaluftee-2x1.jpg";
// import oconaluftee3x2 from "../image/oconaluftee-3x2.jpg";
// import oconaluftee4x3 from "../image/oconaluftee-4x3.jpg";
import theLongManSign16x9 from "../image/the-long-man-sign-16x9.jpg";
// import theLongManSign21x9 from "../image/the-long-man-sign-21x9.jpg";
// import theLongManSign2x1 from "../image/the-long-man-sign-2x1.jpg";
// import theLongManSign3x2 from "../image/the-long-man-sign-3x2.jpg";
// import theLongManSign4x3 from "../image/the-long-man-sign-4x3.jpg";

const Homepage = () => {
  window.scrollTo(0, 0);

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Home`;

  return (
    <div
    // style={{ display: "grid", gridTemplateRows: "auto auto " }}
    >
      <div className="row mt-4">
        {/* <div className="background-map"></div> */}
        <div className="col-12">
          <h1>Cherokee Riverkeepers</h1>
          <div className="col-12">
            <p>
              High in the mountains of Southern Appalachia, the head of the Long
              Man, or Long Person, brings hundreds of rivers, streams and creeks
              to life. The cool, clear waters that flow down steep cliffs and
              tumble over waterfalls nourish river terraces to the north, south,
              east and west. The traditional caretakers of these waters, the
              Cherokees, refer to these rivers as Long Man (Gunahita Asgaya), or
              Long Person (ᏴᏫ ᎬᎾᎯᏔ) – an apt title that reflects the Cherokees
              respect for the consciousness, healing powers and wisdom of
              rivers.
            </p>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="col-12">
          {/* <img
            className="img-fluid"
            src={oconaluftee4x3}
            alt="View of river and bank at Oconaluftee"
          /> */}
          {/* <img
            className="img-fluid"
            src={oconaluftee3x2}
            alt="View of river and bank at Oconaluftee"
          /> */}
          {/* <img
            className="img-fluid"
            src={oconaluftee16x9}
            alt="View of river and bank at Oconaluftee"
          /> */}
          {/* <img
            className="img-fluid"
            src={oconaluftee2x1}
            alt="View of river and bank at Oconaluftee"
          /> */}
          {/* <img
            className="img-fluid"
            src={oconaluftee21x9}
            alt="View of river and bank at Oconaluftee"
          /> */}
          <picture>
            {/* <source media="(main-aspect-ratio: 4/3)" srcset={oconaluftee4x3} /> */}
            {/* <source media="(max-aspect-ratio: 3/2)" srcset={oconaluftee3x2} /> */}
            {/* <source media="(max-aspect-ratio: 16/9)" srcset={oconaluftee16x9} /> */}
            {/* <source media="(max-aspect-ratio: 2/1)" srcset={oconaluftee2x1} /> */}
            {/* <source media="(max-aspect-ratio: 21/9)" srcset={oconaluftee21x9} /> */}
            <img
              className="img-fluid img-thumbnail"
              src={oconaluftee16x9}
              width="4000"
              height="800"
              alt="View of river and bank at Oconaluftee"
            />
          </picture>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <p>
            Mountain waterways are vital to human wellbeing. Globally, mountains
            supply upwards of{" "}
            <a href="https://www.nature.com/articles/s41586-019-1822-y">
              2 billion people
            </a>{" "}
            with freshwater, prompting scientists to refer to them as earth’s ‘
            <a href="http://www.unesco.org/new/fileadmin/MULTIMEDIA/HQ/SC/pdf/SDGs_and_mountains_water_EN.pdf">
              water towers
            </a>
            ’. In the United States, healthy mountains ecosystems are critical
            to watershed sustainability. In Southern Appalachia, the Cherokee
            people have long known the importance of free-flowing mountain
            waterways. For centuries, Cherokees have woven spiritual, scientific
            and historical experiences into a holistic body of knowledge known
            as{" "}
            <a href="https://e360.yale.edu/features/native-knowledge-what-ecologists-are-learning-from-indigenous-people">
              Traditional Ecological Knowledge
            </a>{" "}
            (TEK). In their towns and villages, on their farms, at their fishing
            weirs and over extensive social networks, Cherokees developed a
            language that connected them with their watershed homelands.
          </p>
          <p>
            By the early 1700s, Cherokees felt deep attachment toward the
            rivers, creeks and streams flowing through their homeland. At the
            political core of eighteenth-century Cherokee life, the Overhill
            Cherokees worked with members of the Out Towns, Middle Towns and
            northern Valley Towns to care for the health and wellbeing of the
            Little Tennessee River Basin. Cherokees felt a duty of care to the
            region’s waterways – the veins that connected them to local
            ecosystems and to Cherokees throughout the Little Tennessee River
            Basin, the nearby Hiwassee River Basin and to Cherokees in the Lower
            Towns in the upper reaches of the Savannah River Basin. By the late
            eighteenth century, and under pressure from settler colonists,
            Cherokees began a series of migrations that resulted in the
            establishment of new settlements – the Chickamauga Towns and the New
            Towns – in which knowledge of water traveled with community members.
            Ultimately, the violence of forced removal and the Trail of Tears
            meant that thousands of Cherokees had to adapt their ecological
            knowledge to the ecosystems of Indian Territory, located in
            modern-day eastern Oklahoma.
          </p>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-12 my-4">
          <picture>
            <img
              className="img-fluid img-thumbnail"
              src={theLongManSign16x9}
              width="4000"
              height="800"
              alt="The Long Man Sign by river bank"
            />
          </picture>
        </div>
      </div>
    </div>
  );
};

export default Homepage;
