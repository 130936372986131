import { useRef, useEffect } from "react";

import queryString from "query-string";
import { FeatureGroup, useMap } from "react-leaflet";
import { useParams, useLocation } from "react-router-dom";

const MarkerLayer = ({ markers }) => {
  const location = useLocation();
  let query = queryString.parse(location.search);
  const map = useMap();
  const featureGroupRef = useRef();
  const { placeSlug } = useParams();
  useEffect(() => {
    if (!map) {
      return;
    }

    let activeMarker = null;
    if (placeSlug !== "undefined") {
      const markerLayers = featureGroupRef.current._layers;
      const markerKey = Object.keys(markerLayers).find((key) => {
        return markerLayers[key]._icon.firstChild.dataset.slug === placeSlug;
      });
      activeMarker = markerLayers[markerKey];
    }

    if (
      activeMarker !== null &&
      activeMarker !== undefined &&
      query.lat === undefined &&
      query.lng === undefined &&
      query.zoom === undefined
    ) {
      //leaflet seems buggy with set view so move to 0,0 first

      map.setView([0, 0], 12);
      map.setView(activeMarker.getLatLng(), 12);
    } else {
      const bounds = featureGroupRef.current.getBounds();
      if (
        bounds.isValid() &&
        query.lat === undefined &&
        query.lng === undefined &&
        query.zoom === undefined
      ) {
        map.fitBounds(bounds);
      }
    }

    if (activeMarker) {
      activeMarker.openPopup();
    }
  }, [placeSlug]);

  return <FeatureGroup ref={featureGroupRef}>{markers}</FeatureGroup>;
};
export default MarkerLayer;
