import React from "react";

import GA4React from "ga-4-react";
import ReactDOM from "react-dom";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

const ga4react = new GA4React(process.env.REACT_APP_GA4);

(async (_) => {
  await ga4react
    .initialize()
    .then(
      // (res) => console.log("Analytics Success.")
      (res) => console.log("Analytics Success.")
    )
    .catch(
      // (err) => console.log("Analytics Failure.")
      (err) => console.log("Analytics Failure.")
    )
    .finally(() => {
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById("root")
      );
    });
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
