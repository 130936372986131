import { useState } from "react";

const ClickImage = ({ src }) => {
  const defaultStyle = { cursor: "zoom-in" };
  //style for parallax
  // const activeStyle = {
  //   maxWidth: "100vw",
  //   maxHeight: "calc(100vh - 150px)",
  //   width: "100vw",
  //   height: "100vh",
  //   border: "none",
  //   borderRadius: "0",
  //   objectFit: "contain",
  //   backgroundColor: "rgba(255, 255, 255, 0.9)",
  //   cursor: "zoom-out",
  //   padding: "0",
  // };
  //style for non parallax
  const activeStyle = {
    maxWidth: "100vw",
    maxHeight: "calc(100vh - 105px)",
    width: "100vw",
    height: "calc(100vh - 105px)",
    border: "none",
    borderRadius: "0",
    objectFit: "contain",
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    cursor: "zoom-out",
    padding: "0",
    bottom: "0",
    left: "0",
    margin: "auto",
    // overflow: "auto",
    position: "fixed",
    right: "0",
    top: "5px",
    zIndex: "1000",
  };
  const [zoom, setZoom] = useState(false);

  return (
    <img
      src={src}
      style={zoom ? activeStyle : defaultStyle}
      alt="story illustration"
      onClick={() => setZoom(!zoom)}
    />
  );
};

export default ClickImage;
