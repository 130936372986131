import NavBar from "./Navbar";

const Header = () => {
  return (
    <header className="page-header">
      <NavBar />
    </header>
  );
};

export default Header;
