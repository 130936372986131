import queryString from "query-string";
import { useLocation, Link, useParams } from "react-router-dom";

import PinIcon from "../map/icon/PinIcon";
import HtmlSnippet from "./HtmlSnippet";

const PlaceTable = ({ places }) => {
  const location = useLocation();
  const { pathname } = location;
  const { slug } = useParams();
  const columns = [
    { key: "cherokeeName", name: "Cherokee Name", class: "col-2" },
    { key: "englishName", name: "English Name", class: "col-3" },
    { key: "townType", name: "Town Type", class: "col-3" },
    { key: "preview", name: "Description", class: "col-3" },
  ];

  const baseHeadings = columns.map((column) => (
    <th key={column.key} className={column.class}>
      {column.name}
    </th>
  ));

  const rows = places.map((place) => {
    const geoPoints = place.geoPoint.split(", ");
    const query = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    const searchQuery = {
      lat: geoPoints[0],
      lng: geoPoints[1],
      zoom: 5,
    };
    const stringified = queryString.stringify(
      { ...query, ...searchQuery },
      {
        arrayFormat: "bracket",
      }
    );

    const linkPathname = pathname.startsWith("/story")
      ? "/place"
      : `/map/${slug}`;
    return (
      <tr key={place.id} className="d-flex">
        <td className="col-2">
          <Link to={`${linkPathname}/${place.slug}`}>{place.cherokeeName}</Link>
        </td>
        <td className="col-3">{place.englishName}</td>
        <td className="col-3">{place.townType}</td>
        <td className="col-3">
          <div className="place-content">
            <HtmlSnippet snippet={place.preview} />
          </div>
        </td>

        {pathname.startsWith("/story") && (
          <td className="col-1 text-center">
            <Link to={`/map/${slug}/${place.slug}`}>
              <PinIcon size={"fa-2x"} placeType={place.townType} />
            </Link>
          </td>
        )}
      </tr>
    );
  });
  return (
    <table className="table table-light table-striped">
      <thead>
        <tr className="d-flex">
          {baseHeadings}
          <th className="col-auto"></th>
          {pathname.startsWith("/story") && <th className="col-1">Map</th>}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};

export default PlaceTable;
