import "./scss/app.scss";
import axios from "axios";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import About from "./components/About";
import Homepage from "./components/Homepage";
import Map from "./components/Map";
import PlaceList from "./components/PlaceList";
import PlaceShow from "./components/PlaceShow";
import PostList from "./components/PostList";
import PostShow from "./components/PostShow";
import Page from "./layout/Page";
import Store from "./Store";

const App = () => {
  const url = `${process.env.REACT_APP_API_URL}/auth`;

  axios.get(url, { withCredentials: true }).then((res) => {
    if (url !== res.request.responseURL) {
      window.location.replace(res.request.responseURL);
    }
  });

  return (
    <Router basename={process.env.REACT_APP_BASENAME}>
      <Store>
        <Page>
          <Switch>
            <Route path="/place/:slug" component={PlaceShow} />
            <Route path="/place" component={PlaceList} />
            <Route path="/story/:slug" component={PostShow} />
            <Route path="/story" component={PostList} />
            <Route path="/map/:slug/:placeSlug" component={Map} />
            <Route path="/map/:slug" component={Map} />
            <Route path="/map" component={Map} />
            <Route path="/about" component={About} />
            <Route path="/" component={Homepage} />
          </Switch>
        </Page>
      </Store>
    </Router>
  );
};

export default App;
