import PlaceMarker from "../marker/PlaceMarker";
import BaseContainer from "./BaseContainer";

const PlaceContainer = ({ place }) => {
  const placeMarker = <PlaceMarker key={place.id} place={place} />;
  return (
    <BaseContainer
      markers={[placeMarker]}
      showAggregations={false}
      initialZoom={12}
      center={place.geoData}
    />
  );
};

export default PlaceContainer;
