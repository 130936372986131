import { useEffect, useState } from "react";

import axios from "axios";

import DisplayPost from "../layout/DisplayPost";
import Loading from "../layout/Loading";

const About = () => {
  window.scrollTo(0, 0);

  const slug = "about";
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");
  const emptyPost = {
    id: 0,
    title: "",
    content: "",
    slug: "",
    places: [],
  };
  const [post, setPost] = useState(emptyPost);

  useEffect(() => {
    document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - About`;
  }, []);

  useEffect(() => {
    setIsLoading(true);

    const url = `${process.env.REACT_APP_API_URL}/post/${slug}`;

    axios
      .get(url, { withCredentials: true })
      .then((res) => {
        const post = res.data;
        setError("");
        setPost(post);
        setIsLoading(false);
        // document.getElementById("main").scroll(0, 0);
        // console.log(document.getElementById("main"));
      })
      .catch((error) => {
        if (error.response) {
          setError(error.response.data.error);
          setPost(emptyPost);
          setIsLoading(false);
        } else if (error.request) {
        } else {
        }
      });
  }, [slug]);

  return (
    <>
      {/* <div className="background-map"></div> */}
      <div className="row mt-4 mb-4 justify-content-center">
        <div className="col-12 col-md-12 col-lg-12 col-xl-12">
          {isLoading ? (
            <Loading />
          ) : (
            <>
              {error !== "" ? (
                <div className="text-danger">{error}</div>
              ) : (
                <DisplayPost post={post} displayTitle={true} />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default About;
