const Loading = () => {
  return (
    <div className="loading-placeholder">
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-10">
          <div className="spinner-border text-secondary mt-4" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loading;
