import { Collapse } from "bootstrap";
import { NavLink } from "react-router-dom";

const NavBar = () => {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
      <div className="container">
        <NavLink to="/" className="navbar-brand">
          Cherokee Riverkeepers
        </NavLink>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNavAltMarkup"
          aria-controls="navbarNavAltMarkup"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse " id="navbarNavAltMarkup">
          <div className="navbar-nav flex-row flex-wrap">
            <NavLink
              exact={true}
              to="/about"
              className="nav-link col-3 col-lg-auto"
              activeClassName="active"
            >
              About
            </NavLink>
            <NavLink
              exact={true}
              to="/map"
              className="nav-link col-3 col-lg-auto"
              activeClassName="active"
            >
              Map
            </NavLink>
            <NavLink
              exact={true}
              to="/place"
              className="nav-link col-3 col-lg-auto"
              activeClassName="active"
            >
              Places
            </NavLink>
            <NavLink
              exact={true}
              to="/story"
              className="nav-link col-3 col-lg-auto"
              activeClassName="active"
            >
              Stories
            </NavLink>
            {/* <a
              className="nav-link col-12 col-lg-auto"
              href={process.env.REACT_APP_ADMIN_HOME_URL}
            >
              Admin
            </a> */}
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
