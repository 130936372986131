import L from "leaflet";
import queryString from "query-string";
import { MapContainer, TileLayer, LayersControl } from "react-leaflet";
import { useLocation, Link } from "react-router-dom";

import HtmlSnippet from "../../layout/HtmlSnippet";
import Loading from "../../layout/Loading";
import ZoomDrag from "../navigation/ZoomDrag";

const BaseContainer = ({
  markers = [],
  center = [35.664348416490235, -83.50879270462289],
  initialZoom = 8,
  showAggregations = false,
  isLoading = false,
  aggregations = [],
  legend = null,
  place = null,
}) => {
  const maxZoom = 14;

  const location = useLocation();
  const query = queryString.parse(location.search);

  if (query.lat && query.lng) {
    center = [parseFloat(query.lat), parseFloat(query.lng)];
  }

  if (query.zoom) {
    initialZoom = parseInt(query.zoom);
  }

  return (
    <div className="map-wrapper">
      {showAggregations ? (
        <div className="map-filters">
          {isLoading ? (
            <Loading />
          ) : (
            <div>
              {aggregations.map((aggregation) => (
                <div key={aggregation.name}>
                  <h2>{aggregation.name}</h2>
                  {/* <AggregationList
                    aggregations={aggregation.aggregations}
                    pathname="/map"
                  /> */}
                </div>
              ))}
            </div>
          )}
        </div>
      ) : (
        <div></div>
      )}
      <div className="map-map">
        {isLoading ? (
          <Loading />
        ) : (
          <MapContainer
            center={center}
            zoom={initialZoom}
            maxZoom={maxZoom}
            scrollWheelZoom={true}
            style={{ flex: "1" }}
            tap={false}
            id="ce-map"
          >
            <LayersControl position="topright">
              <LayersControl.BaseLayer
                checked
                name="U.S. Geological Survey - Topography"
              >
                <TileLayer
                  attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
                  url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSTopo/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 19 : 20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Esri - Physical">
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Source: US National Park Service"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 7 : 8}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Open Street Map - Colour">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 19 : 20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Open Street Map - Black And White">
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 19 : 20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="U.S. Geological Survey - Imagery">
                <TileLayer
                  attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
                  url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryOnly/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 19 : 20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="U.S. Geological Survey - Hybrid">
                <TileLayer
                  attribution='Tiles courtesy of the <a href="https://usgs.gov/">U.S. Geological Survey</a>'
                  url="https://basemap.nationalmap.gov/arcgis/rest/services/USGSImageryTopo/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 19 : 20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Esri - Topography">
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Esri, DeLorme, NAVTEQ, TomTom, Intermap, iPC, USGS, FAO, NPS, NRCAN, GeoBase, Kadaster NL, Ordnance Survey, Esri Japan, METI, Esri China (Hong Kong), and the GIS User Community"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 19 : 20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Esri - Imagery">
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 19 : 20}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Esri - Terrain">
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Source: USGS, Esri, TANA, DeLorme, and NPS"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Terrain_Base/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 12 : 13}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Esri - Shaded Relief">
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Source: Esri"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Shaded_Relief/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 12 : 13}
                />
              </LayersControl.BaseLayer>

              <LayersControl.BaseLayer name="Esri - Ocean">
                <TileLayer
                  attribution="Tiles &copy; Esri &mdash; Sources: GEBCO, NOAA, CHS, OSU, UNH, CSUMB, National Geographic, DeLorme, NAVTEQ, and Esri"
                  url="https://server.arcgisonline.com/ArcGIS/rest/services/Ocean_Basemap/MapServer/tile/{z}/{y}/{x}"
                  detectRetina={true}
                  maxNativeZoom={L.Browser.retina ? 9 : 10}
                />
              </LayersControl.BaseLayer>
              <LayersControl.BaseLayer name="Blank">
                <TileLayer
                  attribution=""
                  url=""
                  // detectRetina={true}
                  // maxNativeZoom={L.Browser.retina ? 9 : 10}
                />
              </LayersControl.BaseLayer>

              <LayersControl.Overlay
                checked
                name="USGS National Hydrography Dataset"
              >
                <TileLayer
                  attribution='&copy; <a href="https://www.sciencebase.gov/catalog/item/5d30c270e4b01d82ce84a96b">USGS National Hydrography Dataset</a> Plus High Resolution (NHDPlus HR) for 4-digit Hydrologic Unit - 0513 (published 20180813)'
                  url={`${process.env.REACT_APP_SAN_URL}/tiles/nhd/{z}/{x}/{y}.png`}
                  detectRetina={false}
                  maxNativeZoom={14}
                  bounds={[
                    [24.08469681194938, -93.62002033501962],
                    [41.002824133793105, -71.93254366498057],
                  ]}
                />
              </LayersControl.Overlay>
            </LayersControl>
            {markers}

            {legend}
            <ZoomDrag />
          </MapContainer>
        )}
      </div>
      <div className="placeModal">
        <div
          className="modal fade"
          id="staticBackdrop"
          // data-bs-backdrop="static"
          data-bs-keyboard="true"
          tabIndex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="staticBackdropLabel">
                  {place && place.cherokeeName}{" "}
                </h5>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                {place && (
                  <div className="row mt-4">
                    <div className="col-12">
                      <table className="table table-light">
                        <tbody>
                          {place.englishName !== "" && (
                            <tr>
                              <th>English Name</th>
                              <td>{place.englishName}</td>
                            </tr>
                          )}
                          {place.townType !== "" && (
                            <tr>
                              <th>Town Type</th>
                              <td>{place.townType}</td>
                            </tr>
                          )}
                          {place.meaning !== "" && (
                            <tr>
                              <th>Meaning</th>
                              <td>{place.meaning}</td>
                            </tr>
                          )}
                          {place.type !== "" && (
                            <tr>
                              <th>Type</th>
                              <td>{place.type}</td>
                            </tr>
                          )}
                          {place.geoPoint !== "" && (
                            <tr>
                              <th>Geo Point</th>
                              <td>{place.geoPoint}</td>
                            </tr>
                          )}
                          {place.notes !== "" && (
                            <tr>
                              <th>Notes</th>
                              <td>{place.notes}</td>
                            </tr>
                          )}
                          {place.content !== "" && (
                            <tr>
                              <th>Description</th>
                              <td>
                                <div className="place-content">
                                  <HtmlSnippet snippet={place.content} />
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                      {/* Need to close modal if using this in same window*/}
                      <Link to={`/place/${place.slug}`} target="_blank">
                        View this record on a separate page
                      </Link>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BaseContainer;
