import L from "leaflet";
import ReactDOMServer from "react-dom/server";
import { Marker, Tooltip, Popup } from "react-leaflet";
import { useHistory, useParams, useLocation } from "react-router-dom";

import PinIcon from "../icon/PinIcon";

const PlaceMarker = ({ place, handleModalPlace = null }) => {
  const history = useHistory();
  const location = useLocation();
  const { slug } = useParams();

  const pinheadIconAnchor = [18, 40];
  const pinheadIconSize = [36, 40];
  const pinheadPopupAnchor = [5, 0];
  const pinIcon = L.divIcon({
    iconAnchor: pinheadIconAnchor,
    iconSize: pinheadIconSize,
    popupAnchor: pinheadPopupAnchor,
    html: ReactDOMServer.renderToString(
      <PinIcon placeType={place.townType} slug={place.slug} />
    ),
  });

  const handleClick = (place) => {
    if (handleModalPlace) {
      handleModalPlace(place.slug);
    }
  };

  const button = location.pathname.startsWith("/map/") && (
    <button
      type="button"
      className="btn btn-link"
      data-bs-toggle="modal"
      data-bs-target="#staticBackdrop"
      onClick={() => handleClick(place)}
    >
      View more
    </button>
  );

  return (
    <Marker
      key={place.id}
      position={place.geoData}
      icon={pinIcon}
      eventHandlers={{
        click: (e) => {
          if (location.pathname.startsWith("/map/")) {
            history.push(`/map/${slug}/${place.slug}${location.search}`);
          }
        },
      }}
    >
      <Tooltip>{place.cherokeeName}</Tooltip>
      <Popup
        onClose={() => {
          if (location.pathname.startsWith("/map/")) {
            history.push(`/map/${slug}${location.search}`);
          }
        }}
      >
        <h1>
          <small>{place.cherokeeName}</small>
        </h1>
        {/* {place.englishName !== "" && (
          <div>
            <strong>English Name:</strong> {place.englishName}
          </div>
        )} */}
        {place.townType !== "" && (
          <div>
            <strong>Town Type:</strong> {place.townType}
          </div>
        )}
        {place.meaning !== "" && (
          <div>
            <strong>Meaning:</strong> {place.meaning}
          </div>
        )}
        {/* {place.type !== "" && (
          <div>
            <strong>Type:</strong> {place.type}
          </div>
        )} */}
        {/* {place.location !== "" && (
          <div>
            <strong>Location:</strong> {place.location}
          </div>
        )} */}
        {place.geoPoint !== "" && (
          <div>
            <strong>Location:</strong> {place.geoPoint}
          </div>
        )}
        {/* {place.preview !== "" && (
          <div>
            <div className="place-content">
              <strong>Content:</strong> <HtmlSnippet snippet={place.preview} />
              <Link to={`/place/${place.slug}`}>View more</Link>
            </div>
          </div>
        )} */}

        {button}
      </Popup>
    </Marker>
  );
};

export default PlaceMarker;
