import { useEffect, useState } from "react";

import axios from "axios";
// import { Modal } from "bootstrap";

import { townTypes } from "../../utils/entityTypes";
import PinIcon from "../icon/PinIcon";
import MarkerLayer from "../layer/MarkerLayer";
import PlaceMarker from "../marker/PlaceMarker";
import BaseContainer from "./BaseContainer";

const ExploreContainer = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [places, setPlaces] = useState([]);
  const [modalPlace, setModalPlace] = useState(null);
  const [map, setMap] = useState({
    center: [35.664348416490235, -83.50879270462289],
    zoom: 8,
  });

  const handleModalPlace = (placeSlug) => {
    setModalPlace(null);

    const url = `${process.env.REACT_APP_API_URL}/place/${placeSlug}`;

    axios.get(url, { withCredentials: true }).then((res) => {
      const place = res.data;
      setModalPlace(place);
    });
  };

  useEffect(() => {
    setIsLoading(true);
    const url = `${process.env.REACT_APP_API_URL}/map`;

    axios.get(url, { withCredentials: true }).then((res) => {
      const map = res.data.map;
      const places = res.data.places;

      setMap(map);
      setPlaces(places);
      setIsLoading(false);
    });
  }, []);

  const POSITION_CLASSES = {
    bottomleft: "leaflet-bottom leaflet-left",
    bottomright: "leaflet-bottom leaflet-right",
    topleft: "leaflet-top leaflet-left",
    topright: "leaflet-top leaflet-right",
  };

  const placeMarkers = places.map((place) => {
    return (
      <PlaceMarker
        key={place.slug}
        place={place}
        handleModalPlace={handleModalPlace}
      />
    );
  });
  const markers = <MarkerLayer markers={placeMarkers} isLoading={isLoading} />;
  const place = { name: "foo" };
  const legend = (
    <div className={POSITION_CLASSES.bottomleft}>
      <div
        className="leaflet-control leaflet-bar"
        style={{
          backgroundColor: "white",
          padding: "10px",
          opacity: "0.9",
          height: "clamp(100px, calc(100vh - 255px), 255px)",
          overflowY: "auto",
          lineHeight: "normal",
        }}
      >
        <ul className="list-unstyled my-0">
          {Object.keys(townTypes).map((townType) => {
            return (
              <li key={townType}>
                <div className="d-flex align-items-center">
                  <PinIcon size={"fa-2x"} placeType={townType} />{" "}
                  <div>
                    <span className="">{townType}</span>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  return (
    <>
      <BaseContainer
        isLoading={isLoading}
        markers={markers}
        showAggregations={false}
        center={map.center}
        initialZoom={map.zoom}
        legend={legend}
        place={modalPlace}
      />
    </>
  );
};

export default ExploreContainer;
