import { Fragment } from "react";
import { useEffect, useContext } from "react";

import { Link } from "react-router-dom";

import divider from "../image/divider.svg";
import oconaluftee16x9 from "../image/oconaluftee-16x9.jpg";
import oconaluftee21x9 from "../image/oconaluftee-21x9.jpg";
import oconaluftee2x1 from "../image/oconaluftee-2x1.jpg";
import oconaluftee3x2 from "../image/oconaluftee-3x2.jpg";
import oconaluftee4x3 from "../image/oconaluftee-4x3.jpg";
import about from "../image/story/about.jpg";
import environmentColonialism from "../image/story/environment-colonialism.jpg";
import rebuildingConnections from "../image/story/rebuilding-connections.jpg";
import socialChanges from "../image/story/social-changes.jpg";
import theFirstRiverkeepers from "../image/story/the-first-riverkeepers.jpg";
import theMountains from "../image/story/the-mountains.jpg";
import watershedCommunities from "../image/story/watershed-communities.jpg";
import HtmlSnippet from "../layout/HtmlSnippet";
import Loading from "../layout/Loading";
import PostNavBar from "../layout/PostNavbar";
import { Context } from "../Store";

const PostList = () => {
  const [state] = useContext(Context);
  const storyImages = {
    about: about,
    "environment-colonialism": environmentColonialism,
    "rebuilding-connections": rebuildingConnections,
    "social-changes": socialChanges,
    "the-first-riverkeepers": theFirstRiverkeepers,
    "the-mountains": theMountains,
    "watershed-communities": watershedCommunities,
  };

  document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Stories`;
  let navBar = Loading;

  if (!state.error && state.posts) {
    navBar = <PostNavBar posts={state.posts} path="/story/" style={{}} />;
  }
  // let table = Loading;
  let divs = Loading;
  if (!state.error && state.posts) {
    // const rows = state.posts.map((post) => {
    //   return (
    //     <tr key={post.id} className="d-flex">
    //       <td className="col-4">
    //         <h2>
    //           <Link to={`/story/${post.slug}`}>{post.title}</Link>
    //         </h2>
    //       </td>
    //       <td className="col-8">
    //         <div className="post-list">
    //           <HtmlSnippet snippet={post.preview} />
    //         </div>
    //       </td>
    //     </tr>
    //   );
    // });
    // table = (
    //   <table className="table">
    //     <tbody>{rows}</tbody>
    //   </table>
    // );

    const layers = state.posts.map((post, index) => (
      <Fragment key={post.id}>
        <div className="parallax__group_list" style={{ height: "20vh" }}>
          <div className="parallax__layer parallax__layer--base">
            <div className="title"></div>
          </div>
          <div className="parallax__layer parallax__layer--back">
            <div className="title">
              {/* <Link to={`/story/${post.slug}`}>{post.title}</Link> */}
              {post.title}
            </div>
          </div>
        </div>
        <div className="parallax__group_list" style={{ zIndex: 10 }}>
          <div className="parallax__layer parallax__layer--fore">
            <div className="title"></div>
          </div>
          <div className="parallax__layer parallax__layer--base">
            <div className="title">
              <img
                src={storyImages[post.slug]}
                className="img-thumbnail"
                style={{ maxHeight: "50vh", maxWidth: "50vw" }}
                alt={post.title}
              />
            </div>
          </div>
        </div>
        <div className="parallax__group_list" style={{ height: "150vh" }}>
          {/* <div className="parallax__layer parallax__layer--base">
            <div className="title"></div>
          </div> */}
          <div className="parallax__layer parallax__layer--back">
            <div className="title title-summary" style={{ maxWidth: "80vw" }}>
              <div style={{ textAlign: "justify" }}>
                <HtmlSnippet snippet={post.preview} />
              </div>
              <Link className="text-cr-red" to={`/story/${post.slug}`}>
                Read More…
              </Link>
              <div
                style={{
                  maxWidth: "80vw",
                  margin: "0 0 5rem 0",
                  padding: "20vw",
                }}
              >
                <img
                  className="divider"
                  src={divider}
                  style={{ width: "25vw" }}
                  alt="divider"
                />
              </div>
            </div>
          </div>
          {/* <div className="parallax__layer parallax__layer--deep">
            <div className="title"></div>
          </div> */}
        </div>
        {/* <div className="parallax__group_list" style={{ height: "100vh" }}>
          <div className="parallax__layer parallax__layer--base">
            <div className="title"></div>
          </div>
          <div className="parallax__layer parallax__layer--back">
            <div className="title">
              <img src={divider} style={{ width: "25vw" }} alt="divider" />
            </div>
          </div>
          <div className="parallax__layer parallax__layer--deep">
            <div className="title"></div>
          </div>
        </div> */}
      </Fragment>
    ));

    divs = (
      <div className="parallax">
        <div className="parallax__group_list" style={{ height: "100vh" }}>
          <div className="parallax__layer parallax__layer--base">
            <div className="title"></div>
          </div>
          <div className="parallax__layer parallax__layer--back">
            <div className="title title-header">Stories</div>
          </div>
        </div>
        <div className="parallax__group_list" style={{ zIndex: 10 }}>
          <div className="parallax__layer parallax__layer--fore">
            <div className="title"></div>
          </div>
          <div className="parallax__layer parallax__layer--base">
            <div className="title">
              <img
                src={oconaluftee4x3}
                className="img-thumbnail"
                style={{ width: "50vw" }}
                alt="Riverbank"
              />
            </div>
          </div>
        </div>
        {/* <div className="parallax__group_list" style={{ height: "10vh" }}>
          <div className="parallax__layer parallax__layer--base">
            <div className="title"></div>
          </div>
          <div className="parallax__layer parallax__layer--back">
            <div className="title">
              <img src={divider} style={{ width: "100vw" }} alt="divider" />
            </div>
          </div>
          <div className="parallax__layer parallax__layer--deep">
            <div className="title"></div>
          </div>
        </div> */}
        {layers}
      </div>
    );
  }
  return (
    <>
      {navBar}
      <div className="fullscreen-main-container">
        <div className="row">
          <div className="col-12">{divs}</div>
        </div>
        {/* {divs} */}
      </div>
    </>
  );
};

export default PostList;
