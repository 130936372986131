import { useEffect } from "react";

import queryString from "query-string";
import { useMap } from "react-leaflet";
import { useLocation, useHistory } from "react-router-dom";

const ZoomDrag = () => {
  const map = useMap();
  const location = useLocation();
  const history = useHistory();

  const onMove = () => {
    const query = queryString.parse(location.search, {
      arrayFormat: "bracket",
    });
    const searchQuery = {
      lat: map.getCenter().lat,
      lng: map.getCenter().lng,
      zoom: map.getZoom(),
    };
    const stringified = queryString.stringify(
      { ...query, ...searchQuery },
      {
        arrayFormat: "bracket",
      }
    );
    history.push({
      pathname: location.pathname,
      search: stringified,
    });
  };

  useEffect(() => {
    map.on("moveend", onMove);
    map.on("zoomend", onMove);
    return () => {
      map.off("moveend", onMove);
      map.off("zoomend", onMove);
    };
  });
  return null;
};

export default ZoomDrag;
