import React, { useEffect, useContext } from "react";

import axios from "axios";
import { useLocation } from "react-router-dom";

import { Context } from "../Store";
import Footer from "./Footer";
import Header from "./Header";

const Page = (props) => {
  const location = useLocation();
  const [state, dispatch] = useContext(Context);

  const overflowClass =
    (location.pathname.startsWith("/map") ||
      location.pathname.startsWith("/story")) &&
    !location.pathname.startsWith("/story/")
      ? "container-fluid page-map"
      : "container";

  const fullscreenStyle =
    (location.pathname.startsWith("/map") ||
      location.pathname.startsWith("/story")) &&
    !location.pathname.startsWith("/story/")
      ? "fullscreen"
      : "";

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_URL}/post/`;

    axios
      .get(url, { withCredentials: true })
      .then((res) => {
        const posts = res.data;
        dispatch({ type: "SET_POSTS", payload: posts });
      })
      .catch((error) => {
        dispatch({ type: "SET_ERROR", payload: error });
      });
  }, []);

  return (
    <div className={`page ${fullscreenStyle}`}>
      <Header />
      <div id="main" className={`page-content ${overflowClass}`}>
        {props.children}
      </div>
      <Footer />
    </div>
  );
};

export default Page;
