import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { useLocation, Link, useParams } from "react-router-dom";

import ClickImage from "../layout/ClickImage";
import HtmlSnippet from "./HtmlSnippet";
import PlaceTable from "./PlaceTable";

const DOMPurify = require("dompurify")(window);

const DisplayPost = ({ post, displayTitle = true }) => {
  const location = useLocation();
  const { pathname } = location;
  const imageTransform = (node, index) => {
    if (node.name === "img") {
      return <ClickImage key={index} src={node.attribs.src} />;
    }
    return convertNodeToElement(node, index, imageTransform);
  };

  return (
    <div>
      {displayTitle && (
        <>
          {pathname.startsWith("/map") ? (
            <h1 className="">
              <Link to={`/story/${post.slug}`}>{post.title}</Link>
            </h1>
          ) : (
            <h1 className="">{post.title}</h1>
          )}
        </>
      )}
      <div className="post-show mt-2">
        {/* <HtmlSnippet snippet={post.content} /> */}
        {ReactHtmlParser(DOMPurify.sanitize(post.content), {
          transform: imageTransform,
        })}
      </div>
      {post.places.length > 0 && (
        <>
          <h2>Places linked to this story</h2>

          {/* { TODO: display a map with these places?} */}
          {/* <ul className="list-unstyled">{places}</ul> */}
          <PlaceTable places={post.places} />
        </>
      )}
    </div>
  );
};

export default DisplayPost;
