import { useEffect, useState, useContext } from "react";

import axios from "axios";
import { useHistory, useParams } from "react-router-dom";

import DisplayPost from "../layout/DisplayPost";
import Loading from "../layout/Loading";
import PostNavBar from "../layout/PostNavbar";
import ExploreContainer from "../map/container/ExploreContainer";
import { Context } from "../Store";

const Map = () => {
  const history = useHistory();
  const [state] = useContext(Context);

  const { slug } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [popOutClass, setPopOutClass] = useState("");
  const [firstView, setFirstView] = useState(true);

  const [error, setError] = useState("");
  const emptyPost = {
    id: 0,
    title: "",
    content: "",
    slug: "",
    places: [],
  };
  const [post, setPost] = useState(emptyPost);

  let navBar = Loading;

  if (!state.error && state.posts) {
    if (slug === undefined && state.posts.length > 0) {
      history.push(`/map/${state.posts[0].slug}`);
    }

    navBar = (
      <PostNavBar
        title={post.title}
        posts={state.posts}
        path="/map/"
        style={{}}
      />
    );
  }

  useEffect(() => {
    if (slug) {
      document.title = `${process.env.REACT_APP_BASE_DOCUMENT_TITLE} - Map: ${post.title}`;

      setIsLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/post/${slug}`;

      axios
        .get(url, { withCredentials: true })
        .then((res) => {
          const post = res.data;
          setError("");

          setPost(post);
          setIsLoading(false);
        })
        .catch((error) => {
          if (error.response) {
            setError(error.response.data.error);
            setPost(emptyPost);
            setIsLoading(false);
          } else if (error.request) {
          } else {
          }
        });
      const myDiv = document.getElementById("map-show-post-container");
      myDiv.scrollTop = 0;
      if (firstView === true) {
        setFirstView(false);
      } else {
        setPopOutClass("slide-out-open");
      }
    }
  }, [slug]);

  return (
    <>
      <div>{navBar}</div>
      <div className="fullscreen-main-container">
        <div className="overflow-auto map-show-map map-show-border">
          <ExploreContainer />
          <div id="slide-out" className={`slide-out-width ${popOutClass}`}>
            <div
              className="slide-out-tab"
              onClick={() =>
                setPopOutClass("" === popOutClass ? "slide-out-open" : "")
              }
            >
              <div>Read Story</div>
            </div>
            <div id="map-show-post-container" className="p-4">
              {slug && (
                <>
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      {error !== "" ? (
                        <div className="text-danger">{error}</div>
                      ) : (
                        <>
                          <DisplayPost post={post} />
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Map;
